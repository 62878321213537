import { upload } from '@/service/base'

type ImageUploadParams = {
  file: File
  onProgressCallback: (progress: number) => void
  onSuccessCallback: (res: { id: string }) => void
  onErrorCallback: () => void
}
type ImageUpload = (v: ImageUploadParams, isPublic?: boolean, url?: string) => void
export const imageUpload: ImageUpload = ({
  file,
  onProgressCallback,
  onSuccessCallback,
  onErrorCallback,
}, isPublic, url) => {
  const formData = new FormData()
  formData.append('file', file)
  const onProgress = (e: ProgressEvent) => {
    if (e.lengthComputable) {
      const percent = Math.floor(e.loaded / e.total * 100)
      onProgressCallback(percent)
    }
  }

  upload({
    xhr: new XMLHttpRequest(),
    data: formData,
    onprogress: onProgress,
  }, isPublic, url)
    .then((res: { id: string }) => {
      onSuccessCallback(res)
    })
    .catch(() => {
      onErrorCallback()
    })
}

export const cropImageToSquare: (file: File) => Promise<string> = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // 计算最大正方形的边长
        const side = Math.min(img.width, img.height)

        // 设置canvas的尺寸
        canvas.width = side
        canvas.height = side

        // 剪裁图片并绘制到canvas上
        ctx?.drawImage(img, (img.width - side) / 2, (img.height - side) / 2, side, side, 0, 0, side, side)

        // 将canvas内容转换为data URL
        resolve(canvas.toDataURL('image/jpeg', 0.3)) // 图片压缩比控制输出图片质量
      }
      img.src = e.target?.result as string
    }
    reader.readAsDataURL(file)
  })
}

function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--)
    u8arr[n] = bstr.charCodeAt(n)

  return new Blob([u8arr], { type: mime })
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const blob = dataURLtoBlob(dataurl)
  return new File([blob], filename, { type: blob.type })
}
